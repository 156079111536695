import React from 'react';

const Feature = ({href, image, heading, description, to}) =>
  <section>
    <a href={href} className="image" alt="image">
      <div className="image-cover"><img src={image} alt="" data-position="center center" /></div>
    </a>
    <div className="content">
      <div className="inner">
        <h2>{heading}</h2>
        <p>{description}</p>
      </div>
    </div>
  </section>

export default Feature;
