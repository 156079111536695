import React from 'react';
import CapabilityList from './CapabilityList';

const Capabilities = () =>
  <section id="two" className="wrapper style3 fade-up">
    <div className="inner">
      <h2>Why choose Petits Kickers Soccer Program?</h2>
      <p>
        During our soccer sessions, we teach children the foundation of the game of soccer by teaching them dribbling, passing, receiving, and shooting skills.
        We keep it fun and lively with games keeping children engaged throughout the sessions.
      </p>
      <h2>Get to know coach Ibrahim</h2>
      <br/>
      <div className="features">
        <CapabilityList />
      </div>
    </div>
  </section>

export default Capabilities;
