import React from 'react';

import '../../assets/sass/main.scss';

const getYear = () => {
    var currentDate = new Date();
    return currentDate.getFullYear();
};

const Footer = () => (
  <footer id="footer" className="wrapper style3">
    <div className="inner">
      <ul className="menu">
        <li>&copy; 2020-{getYear()} Petits Kickers LLC. All rights reserved.</li>
      </ul>
    </div>
  </footer>
);

export default Footer;
