import React from 'react';
import Scroll from '../common/Scroll';
import logo from '../../images/logo.png'

const Introduction = () =>
  <section id="intro" className="wrapper style1 fullscreen fade-up">
    <div className="inner">
      <h1> <img className="logo" alt="petits kickers" src={logo} />   Petits Kickers Soccer Program</h1>
      <p>
        Providing group soccer lessons to children ages 3 to 6 in the Kansas City area.
      </p>
      <ul className="actions">
        <li>
          <Scroll type="id" element="one">
            <a href="#one" className="button">
              Learn more
            </a>
          </Scroll>
        </li>
      </ul>
    </div>
  </section>


export default Introduction;
