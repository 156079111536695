import React from "react";

const ContactInformation = () =>
  <section>
    <ul className="contact">
      <li>
        <h3>Address</h3>
        <span>
          15050 W 138th ST #3083
          <br />
          Olathe, KS 66063
          <br />
          USA
        </span>
      </li>
      <li>
        <h3>Email</h3>
        <a href="mailto:ikante@petitskickersllc.com">ikante@petitskickersllc.com</a>
      </li>
      <li>
        <h3>Phone</h3>
        <span>(913) 952-8252</span>
      </li>
    </ul>
  </section>

export default ContactInformation;
