import React from 'react';

import pic1 from '../../images/HappyFeet-04.jpg';
import pic2 from '../../images/HappyFeet-30.jpg';
import pic3 from '../../images/HappyFeet-40.jpg';

import Feature from './Feature';

const Features = () => {
  const FEATURES = [
    {
      href: '/#',
      image: pic1,
      heading: 'Our Mission',
      description: 'Our goal at Petits Kickers is to introduce soccer to children boys and girls ages 3 through 6 years old in a way that is not only fun, but also enforces social skills, improves self-awareness and self confidence, and promotes bravery and creativity with a go for it mentality that can help provide essential foundation for the rest of their lives.',
      to: {
        href: '/mission',
        label: 'Learn more',
      },
    },
    {
      href: '/#',
      image: pic2,
      heading: 'Benefits',
      description: 'Soccer is the world\'s most popular sport probably because it is a lifestyle sport that people can play their entire lives. The first stages of athletic advancement entail creating body awareness, strength and fitness. Upon this core, advanced skills and muscle coordination can be developed for any sport.',
      to: {
        href: '/benefits',
        label: 'Learn more',
      },
    },
    {
      href: '/#',
      image: pic3,
      heading: 'What to Expect',
      description: 'Each session will meet once per week. Each session last between 30-45 mins. When weather is bad the session will be in the classroom or in the gym if the center has one. All equipment will be provided.',
      to: {
        href: '/what-to-expect',
        label: 'Learn more',
      },
    },
  ];

  const [features] = React.useState(FEATURES);

  return (
    <section id="one" className="wrapper style2 spotlights">
       {features.map(feature => <Feature key={feature.heading} {...feature} />)}
    </section>
  );
}

export default Features;
