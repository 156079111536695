import React from 'react';

import Capability from './Capability';

const CapabilityList = () => {
  const CAPABILITIES = [
    {
      heading: 'Coaching career',
      description: 'Coach Ibrahim has 10+ years recruiting and coaching at youth soccer clubs such as Kansas Rush and the Kansas Olympic Development Soccer Program (ODP).',
      iconClass: 'fa-users',
    },
    {
      heading: 'Professional soccer career',
      description: 'Ibrahim played at the professional level since 2003 in the MLS, MISL, USL, having won 3 championships in 2004 & 2006 with the Baltimore Blast, and again in 2010 with Monterrey, Mexico.',
      iconClass: 'fa-futbol-o',
    },
    {
      heading: 'Our Purpose',
      description: 'Ibrahim focuses on creating a love for the game of soccer by developing coordination, balance, and ball control.',
      iconClass: 'fa-user-plus',
    },
    {
      heading: 'Our goal',
      description: 'The goal is for children to not only learn the wonderful game of soccer, but to also have fun, build social skills, improve self-confidence and instill a brave creative go for it mentality that can help provide the right foundation for the rest of their lives!',
      iconClass: 'fa-clipboard',
    }
  ]

  const [capabilities] = React.useState(CAPABILITIES);

  return (
    <>
      {capabilities.map((capability) => <Capability key={capability.heading} {...capability} />)}
    </>
  )
}

export default CapabilityList;
