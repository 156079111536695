import React from 'react';
import ContactInformation from './ContactInformation';

const Contact = () =>
  <section id="three" className="wrapper style2 fade-up">
    <div className="inner">
      <h2>Get in touch</h2>
      <p>
        Does this sound interesting to your center? If so, please feel free to reach out to me and we can schedule a FREE demo day with your center!
      </p>
      <div className="style1">
        <ContactInformation />
      </div>
    </div>
  </section>

export default Contact;
